<!-- Features start -->
<section class="section products" id="projects">
  <div class="container">
    <div
      class="uFont f-title3-main upper"
    >
      Продукты
    </div>


    <div class="flex flexColumn product_outsource">
      
      <div class="rowText rFont f-body1-black">
        Опыт разработки аутсорсинговых проектов позволил нам получить экспертные
        знания в таких направлениях, как:
      </div>
      <div class="flex">
        <div class="rowList">
          <div class="listItem flex rFont f-body1-black animate_product1 ">
            <!-- <div class="rectangle"></div> -->
            <div class="text">
              Информационные и транзакционные банковские системы для обслуживания
              физических и юридических лиц
            </div>
          </div>
          <div class="listItem flex rFont f-body1-black animate_product2 ">
            <!-- <div class="rectangle"></div> -->
            <div class="text">Интеграция с банковскими системами</div>
          </div>
          <div class="listItem flex rFont f-body1-black item-3 animate_product3 ">
            <!-- <div class="rectangle"></div> -->
            <div class="text">
              Системы потребительского кредитования — обеспечение on-line
              кредитования в магазинах
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- end row -->
    <div class="flexColumn" style="margin: 70px 0; gap: 20px;">
      <div class="flexColumn gap-10">
        <div class="rowTitle uFont f-title3-black2 upper">
          Собственные продукты
        </div>
        <div class="rFont f-body1-black" style="max-width: 500px;">Накопленные знания и опыт помогли нам в реализации собственных идей</div>  
      </div>
      <div class="productsList flexColumn">
        <div class="flex our_product">
          <div class="img_container">
            <img src="assets/images/services.png" />
          </div>
          <div class="description flexColumn">
            <div class="header">Цифровизация процессов в товариществах и кооперативах</div>
            <div class="text">
              <p>Нашей задачей было решение текущих проблем членов товариществ и кооперативов. </p>
              <p>Проанализировав потребности ЦА и особенности законодательства, мы разработали ключевые возможности сервисов.</p>
              <p>Разработанные сервисы помогли упростить, удешевить и ускорить процесс принятия решений, помочь в других аспектах деятельности товариществ и кооперативов.</p>
              <p>Приобретенный ранее опыт помог нам обеспечить высокий уровень безопасности, стабильности и производительности.</p>
            </div>
  
            <div class="flexColumn service_links">
              <div class="flex link_list">
                <a target='_blank' href="https://itdom.by" class="link flex">
                  <div class="main_container">
                    <div class="title">ITdom</div>
                    <div class="caption">Товарищества собственников и жилищные кооперативы</div>  
                  </div>
                  <div class="link">Подробнее</div>
                </a>
                <a target='_blank' href="https://it-dacha.by" class="link flex">
                  <div class="main_container">
                    <div class="title">ITdacha</div>
                    <div class="caption">Садоводческие товарищества</div>  
                  </div>
                  <div class="link">Подробнее</div>
                </a>
                <a target='_blank' href="https://itgarage.by" class="link flex">
                  <div class="main_container">
                    <div class="title">ITgarage</div>
                    <div class="caption">Гаражные кооперативы</div>  
                  </div>
                  <div class="link">Подробнее</div>
                </a>
              </div>
              
            </div>
          </div>
        </div>
        
        <div class="flex our_product">
          <div class="description flexColumn">
            <div class="header">Инновационный сервис заказа товаров и услуг</div>
            <div class="text">
              <p>Мы разработали чат-ботов, которые помогают наладить связь между
                покупателями и продавцами, что позволяет им получать различные товары и
                услуги буквально в пару кликов.
              </p>
            </div>
  
            <div class="flexColumn service_links">
              <div class="flex link_list">
                <a target='_blank' href="https://t.me/hochu_by_bot" class="link flex">
                  <div class="main_container">
                    <div class="title">Хочу! – заказывай просто</div>
                    <div class="caption">Telegram-бот</div>  
                  </div>
                  <div class="link">Подробнее</div>
                </a>  
              </div>
            </div>
          </div>
          <div class="img_container">
            <img src="assets/images/services_2.png"/>
          </div>
        </div>
      </div>
      
    </div>

    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Features end -->
